import { graphql } from 'gatsby';
import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';
import image from '../images/cheval_interieur.jpg'


const Cavaliers = ({ data }) => {
  const prereq = <><b style={{ color:"green" }}>Pas besoin d'être un cavalier de haut niveau, ON  NE MONTE PAS LE CHEVAL.</b></>;

  return (
    
    <Layout>
      <SEO title="Cavaliers" />
      <Header metadata={data.site.siteMetadata} />
      <br/><br/>
      {prereq}
      <br/><br/>
      <b>Développer votre cheval intérieur</b>
      <br/><br/>
      L’equicoaching pour les cavaliers ??? Oui oui et oui. <br/>
      Qui êtes-vous pour votre cheval ? Quelle relation entretenez-vous avec lui? Qu’essaye t’il de vous dire?<br/>
      Découvrez votre cheval interieur avec l’équicoaching.<br/>
      Attention, c’est un travail sur l’humain, pas sur le cheval… lui il est parfait ;-) <br/>
      <br/>
      <img width={450} height={600} src={image} alt=""/>
    </Layout>
  );
};

export default Cavaliers;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
